<template>
  <div>
    <ul
      :class="$style.tracksList"
      :style="{ visibility: selectedTrack ? 'hidden' : 'visible' }"
    >
      <li v-for="track of distinctTracks" :key="track.iswc">
        <button :class="$style.trackBtn" @click="$emit('trackSelected', track)">
          <span :class="$style.trackTitle">{{ track.title }}</span>
          <span :class="$style.trackIswc">{{ track.iswc }}</span>
        </button>
      </li>
    </ul>
    <footer v-show="tracks" :class="$style.footer">
      <router-link
        :class="$style.viewLink"
        :to="`/catalogs/${catalogId}/search/table`"
        ><BaseIcon :class="$style.viewLinkIcon" icon="table" />Table
        View</router-link
      >
      <div v-if="selectedTrack" :class="$style.statusLegendContainer">
        <StatusLegend
          :foundStatusClasses="{
            circleStyle: $style.foundCircle,
            innerCircleStyle: $style.foundInnerCircle,
          }"
        />
      </div>
      <div v-else :class="$style.chooseCatalog">
        <router-link to="/catalogs">CHOOSE CATALOG</router-link>
      </div>
    </footer>
  </div>
</template>
<script>
import StatusLegend from "./components/StatusLegend";
export default {
  name: "CatalogMapView",
  components: {
    StatusLegend,
  },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
    distinctTracks: {
      type: Array,
      default: () => [],
    },
    catalogId: {
      type: String,
      required: true,
    },
    selectedTrack: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" module>
.tracksList {
  list-style: none;
  padding: 0;
  position: relative;
  height: calc(100vh - 15rem);
  overflow-y: auto;

  li + li {
    margin-top: 1rem;
  }
}

.trackBtn {
  padding: 0;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  line-height: 1.4;
}

.trackTitle,
.trackIswc {
  display: block;
  font-size: 14px;
  font-family: $primary-font;
  letter-spacing: 1.5px;
}

.trackTitle {
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
}

.trackIswc {
  color: $color-grey-light;
}

.viewLink {
  position: fixed;
  bottom: 3rem;
  margin-top: 3rem;
  display: block;
  color: $color-grey-light;
  z-index: 10;

  &:hover {
    color: #333;
  }
}

.viewLinkIcon {
  margin-right: 0.5rem;
}

.footer {
  margin: 3rem 5rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chooseCatalog {
  position: fixed;
  bottom: 3rem;
  right: 5rem;
  font-weight: 500;
  font-size: 14px;
  z-index: 10;
}

.statusLegendContainer {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
}

.foundCircle {
  border: 1px solid #b5b9cc !important;
}

.foundInnerCircle {
  background-color: #b5b9cc !important;
}
</style>